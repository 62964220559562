import categories from './product_categroies.json'
import products from './products.json'
for (const i in products) {
  const product = products[i]
  product.id = i
}

console.log(categories)

const state = () => ({
  categories: categories,
  products: products
})

const getters = {
  i18n: (state) => {
    let i18n = {}
    for (const category of state.categories) {
      const {name, lang} = category
      for (const l in lang) {
        i18n[l] = i18n[l] || {
          products: {
            categories: {},
            list: {}
          }
        }
        i18n[l]["products"]["categories"][name] = lang[l] || {}
      }
    }

    for (const i in state.products) {
      const product = state.products[i]
      const {name, kind, pricing, specification, feature, suitable, recommendations, ingredients} = product
      product.id = i
      for (const l in kind) {
        i18n[l] = i18n[l] || {
          products: {
            categories: {},
            list: {}
          }
        }
        i18n[l]["products"]["list"][i] = i18n[l]["products"]["list"][i] || {
          name: name[l],
          kind: kind[l],
          pricing: pricing[l],
          specification: specification[l],
          feature: feature[l],
          suitable: suitable[l],
          recommendations: recommendations[l],
          ingredients: ingredients[l]
        }
        
      }
    }
    return i18n
  },

  products: (state) => {
    const list = Object.assign(state.products)
    for (const i in list) {
      const product = list[i]
      product.id = i
    }
    return list
  },
  getProductById: (getters) => (id) => {
    const products = getters.products
    const product = products[id]
    return product
  },
  getProductsByCategory: (getters) => (category) => {
    console.log(category)
    const products = getters.products.filter(product => product.category === category)
    return products
  },
  groupByCategory: (getters) => {
    const products = getters.products.reduce((result, item) => {
      const category = item.category;
      result[category] = result[category] || []
      result[category].push(item);    
      return result;
    }, {});

    return products
  }
}

export default {
  namespaced: true,
  state,
  getters
}