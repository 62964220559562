<i18n locale="tw" lang="yml" src="@/locales/about/tw.yml"> 
</i18n>
<i18n locale="cn" lang="yml" src="@/locales/about/cn.yml"> 
</i18n>

<template>
  <div class="about flex flex-wrap">
    <div class="assets flex flex-column align-items-start col-12 md:col-6">
      <img src="https://placehold.co/570x710" alt="">
    </div>
    <div class="stories flex flex-column align-items-start flex-wrap col-12 md:col-6">
      <h4 class="sub-begining">OUR BEGINING</h4>
      <h2 class="title flex" v-html="t('about.title')"></h2>
      <p class="subtitle flex" v-html="t('about.sections[0].subtitle')"></p>
      <p class="flex" v-html="t('about.sections[0].description')"></p>
      <p class="subtitle flex" v-html="t('about.sections[1].subtitle')"></p>
      <ul>
        <li class="subtitle1"><i class="pi pi-circle-fill"></i> {{ t('about.sections[1].descriptions[0].title') }}</li>
        <p v-html="t('about.sections[1].descriptions[0].content')"></p>
        <li class="subtitle1"><i class="pi pi-circle-fill"></i> {{ t('about.sections[1].descriptions[1].title') }}</li>
        <p>
          <ul>
            <li><i class="pi pi-star-fill"></i> <span v-html="t('about.sections[1].descriptions[1].content[0]')"></span></li>
            <li><i class="pi pi-star-fill"></i> <span v-html="t('about.sections[1].descriptions[1].content[1]')"></span></li>
            <li><i class="pi pi-star-fill"></i> <span v-html="t('about.sections[1].descriptions[1].content[2]')"></span></li>
            <li><i class="pi pi-star-fill"></i> <span v-html="t('about.sections[1].descriptions[1].content[3]')"></span></li>
            <br>
            <li><i class="pi pi-thumbs-up-fill"></i><span v-html="t('about.sections[1].descriptions[1].content[4]')"></span></li>
            <li><i class="pi pi-thumbs-up-fill"></i><span v-html="t('about.sections[1].descriptions[1].content[5]')"></span></li>
            <li><i class="pi pi-thumbs-up-fill"></i><span v-html="t('about.sections[1].descriptions[1].content[6]')"></span></li>
          </ul>
        </p>
        
        <li class="subtitle1"><i class="pi pi-circle-fill"></i> {{ t('about.sections[1].descriptions[2].title') }}</li>
        <p v-html="t('about.sections[1].descriptions[2].content')"></p>
      </ul>
    </div>
    <div class="video-container col-12">
      <div class="video-container">
        <iframe src="https://www.youtube.com/embed/xux660-ETHM" title="大禾商品" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})
</script>

<style lang="scss" scoped>
.title{
  margin: 0;
}
.sub-begining {
  letter-spacing: 3px;
  color: #b2b8bf;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  margin: 0;
}
.assets {
  img {
    max-width: 100%;
  }
}

.stories {
  > ul {
    list-style: none;
    padding: 0;
    > .subtitle1 {
      font-size: 20px;
      font-weight: bold;
      color: #c39f20;
    }

    > p > ul {
      list-style: none;
      padding: 0;
    }
  }
  pre {
    text-wrap: wrap;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
  
  > .title {
    font-size: 32px;
    font-weight: bold;
  }

  > p.subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #c39f20;
  }
  
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
  iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>