export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "tw",
    "resource": {
      "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規格"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建議售價"])},
      "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
      "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特色"])},
      "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用對象"])},
      "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用建議"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
      "products": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寶養素A+"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1904G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>富含高營養素熱量, 幫助增強體力</li>\n  <li>參考國人DRIS指標，添加28種營養素</li>\n  <li>100%美國優質雙重動植物蛋搭配</li>\n  <li>優質植物性MCT提供熱量，營養補給</li>\n  <li>高維生素C含量, 有助促進鐵吸收</li>\n  <li>高鐵配方，有助正常紅血球生成</li>\n  <li>添加膳食纖維+益生元，促進腸道蠕動</li>\n  <li>好消化吸收，調節生理機能</li>\n  <li>不含乳糖、蔗糖</li>\n  <li>滲透壓294Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>中老年人、病後補養、無法正常進食者、飲食不均、乳糖不耐者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，每杯提供251大卡，以7平匙奶粉勺+200毫升溫開水沖泡</br>可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麥芽糊精、大豆分離蛋白、椰子油粉(含中鏈三酸甘油酯)、棕櫚油粉、菊糖、氯化鉀、碳酸鈣、重酒石酸膽鹼、硫酸銅(麥芽糊精、硫酸銅)、氯化鈉、香料、氧化鎂、濃縮乳清蛋白、乾酪素鈣、關華豆膠、磷酸鈣、抗壞血酸鈉、維生素E、鉬酵母、硫酸鋅、中鏈三酸甘油酯、鉻酵母、硒酵母、焦磷酸鐵、生物素、菸鹼醯胺、本多酸鈣、維生素A粉末、葡萄糖酸錳、蔗糖素(甜味劑)、維生素B12、維生素D3、維生素B6、維生素B1、維生素B2、維生素K1、葉酸、碘化鉀</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鉻寶養素(燕麥味)"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1824G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>三大營養素符合國人DRIS範圍</li>\n  <li>8種完整B群+鉻，維持醣類代謝力!</li>\n  <li>每日2杯補充172微克鉻，有助維持醣類正常代謝</li>\n  <li>4大草本精華:金盞花(含葉黃素)、武靴葉、葫蘆巴、納豆萃取(含納豆激酶)，調整體質</li>\n  <li>高纖+益生元，促進腸道蠕動幫助消化</li>\n  <li>雙重蛋白添加，有助肌肉生長</li>\n  <li>好消化吸收，調節生理機能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n  <li>滲透壓321Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病後補養、需醣類正常代謝者、嗜甜者、運動健身族、營養補給</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以8平匙奶粉勺+200毫升溫開水沖泡</br>每杯提供254大卡與4.1克纖維，可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分離蛋白、麥芽糊精、椰子油粉(含中鏈三酸甘油酯)、燕麥粉、棕櫚油粉、菊糖、燕麥纖維、氯化鉀、碳酸鈣、磷酸鈣、重酒石酸膽鹼、硫酸銅(麥芽糊精、硫酸銅)、氯化鈉、香料、氧化鎂、濃縮乳清蛋白粉、乾酪素鈣、關華豆膠、抗壞血酸鈉、鉻酵母、檸檬酸鈉、維生素E、中鏈三酸甘油酯、納豆萃取物(含納豆激酶)、鉬酵母、硫酸鋅、武靴葉萃取物、硒酵母、焦磷酸鐵、葫蘆巴萃取物、生物素、菸鹼醯胺、本多酸鈣、維生素A粉末、葡萄糖酸錳、蔗糖素(甜味劑)、維生素B12、維生素D3、維生素B6、維生素B1、維生素B2、吡啶甲酸鉻、金盞花萃取物(含葉黃素)、維生素K1、葉酸、碘化鉀、難消化性麥芽糊精、菸鹼酸鉻。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鉻寶養素(無糖)"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1710G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>三大營養素符合國人DRIS範圍</li>\n  <li>8種完整B群+鉻，維持醣類代謝力!</li>\n  <li>每日2杯補充172微克鉻，有助維持醣類正常代謝</li>\n  <li>4大草本精華:金盞花(含葉黃素)、武靴葉、葫蘆巴、納豆萃取(含納豆激酶)，調整體質</li>\n  <li>高纖+益生元，促進腸道蠕動幫助消化</li>\n  <li>雙重蛋白添加，有助肌肉生長</li>\n  <li>好消化吸收，調節生理機能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n  <li>滲透壓321Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病後補養、需醣類正常代謝者、嗜甜者、運動健身族、營養補給</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日2杯，以8平匙奶粉勺+200毫升溫開水沖泡</br>每杯提供254大卡與4.1克纖維，可搭配早餐或作為點心、睡前飲品\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分離蛋白、麥芽糊精、椰子油粉(含中鏈三酸甘油酯)、燕麥粉、棕櫚油粉、菊糖、燕麥纖維、氯化鉀、碳酸鈣、磷酸鈣、重酒石酸膽鹼、硫酸銅(麥芽糊精、硫酸銅)、氯化鈉、香料、氧化鎂、濃縮乳清蛋白粉、乾酪素鈣、關華豆膠、抗壞血酸鈉、鉻酵母、檸檬酸鈉、維生素E、中鏈三酸甘油酯、納豆萃取物(含納豆激酶)、鉬酵母、硫酸鋅、武靴葉萃取物、硒酵母、焦磷酸鐵、葫蘆巴萃取物、生物素、菸鹼醯胺、本多酸鈣、維生素A粉末、葡萄糖酸錳、蔗糖素(甜味劑)、維生素B12、維生素D3、維生素B6、維生素B1、維生素B2、吡啶甲酸鉻、金盞花萃取物(含葉黃素)、維生素K1、葉酸、碘化鉀、難消化性麥芽糊精、菸鹼酸鉻。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慎養素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>慎選2號-慎養關鍵體力</li>\n  <li>高密度熱量，每杯提供128大卡</li>\n  <li>雙優質蛋白:18%蛋白剛剛好，減少含氮廢物排出</li>\n  <li>慎養配方:低鈉、磷、鉀管理, 少負擔</li>\n  <li>高鐵+葉酸,有助紅細胞正常生成</li>\n  <li>添加6%高纖配方, 調節消化道機能</li>\n  <li>添加天然酵母鉻, 同步有助維持醣類正常代謝</li>\n  <li>牛磺酸及精胺酸: 調節生理機能，精神旺盛</li>\n  <li>無添加乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>需低鈉攝取者、病後補養、水份控制者、蛋白質補充者、增強體力、難正常進食者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日1-3杯，以3平匙奶粉勺+100毫升溫開水沖泡</br>每杯提供128大卡與4.1克纖維，可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麥芽糊精、大豆分離蛋白、濃縮乳清蛋白、菊糖、燕麥纖維、碳酸鈣、重酒石酸膽鹼、磷酸鈣、香料、硫酸銅(麥芽糊精、硫酸銅)、氧化鎂、氯化鉀、抗壞血酸鈉、牛磺酸、維生素E、鉻酵母、鉬酵母、硫酸鋅、生物素、硒酵母、焦磷酸鐵、蔗糖素(甜味劑)、葡萄糖酸錳、菸鹼醯胺、本多酸鈣、維生素A粉末、維生素B12、維生素B6、維生素D3、精胺酸、維生素B2、維生素B1、維生素K1、葉酸、碘化鉀。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D蛋白素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>謹慎1號 3D配方,調整體質</li>\n  <li>10% 雙優質蛋白質補充，氮平衡</li>\n  <li>慎選低鈉磷鉀，管理電解質，減少成積</li>\n  <li>添加MCT優質脂肪，提供高密度能量</li>\n  <li>高鐵配方有助正常紅細胞生成</li>\n  <li>慎利加乘: OPC+南瓜籽+蔓越莓+葉黃素，調節生理機能</li>\n  <li>天然鉻酵母，有助維持醣類正常代謝</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>蛋白質攝取調整、病後補養、低鈉飲食、增強體力、咀嚼能力差者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以5平匙奶粉勺+250毫升溫開水沖泡</br>每杯提供215大卡，可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麥芽糊精、棕櫚油粉、椰子油粉(含中鏈三酸甘油酯)、濃縮乳清蛋白、大豆分離蛋白、菊糖、燕麥纖維、碳酸鈣、重酒石酸膽鹼、香料、氧化鎂、抗壞血酸鈉、葡萄籽萃取物、牛磺酸、蔓越莓萃取物、肌醇、南瓜籽萃取物、維生素E、焦磷酸鐵、鉬酵母、硫酸鋅、鉻酵母、β-胡蘿蔔素、硒酵母、菸鹼醯胺、蔗糖素(甜味劑)、生物素、維生素A粉末、本多酸鈣、葡萄糖酸錳、維生素D3、硫酸銅、維生素B12、維生素B6、維生素B1、維生素B2、維生素K1、合成番茄紅素、金盞花萃取物(含葉黃素)、葉酸、碘化鉀。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["療養素A"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>高密度高質量營養,2杯=1/4每日熱量</li>\n  <li>35種維生素+礦物質，均衡營養配方</li>\n  <li>3重蛋白添加，好消化吸收，有助肌肉生長</li>\n  <li>5倍麩醯胺酸，促進食慾，調整體質</li>\n  <li>添加鋅+維生素C，有助黏膜修復與傷口癒合</li>\n  <li>牛磺酸+精胺酸，增強體力，促進新陳代謝</li>\n  <li>燕麥纖維+菊糖，促進腸道蠕動幫助消化</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>體力虛弱者、產後或術後者、食慾不佳者、咀嚼吞嚥困難者、營養補給</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以5平匙奶粉勺+250毫升溫開水沖泡</br>每杯提供192大卡，可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>麥芽糊精、棕櫚油粉、椰子油粉(含中鏈三酸甘油酯)、大豆分離蛋白、麩醯胺酸、芥花油粉、菊糖、燕麥纖維、牛初乳、精胺酸、氯化鉀、碳酸鈣、磷酸鈣、關華豆膠、重酒石酸膽鹼、香料、濃縮乳清蛋白、乾酪素鈣、牛磺酸、硫酸銅(麥芽糊精、硫酸銅)、中鏈三酸甘油酯、抗壞血酸鈉、維生素E、氧化鎂、鉬酵母、鉻酵母、β-胡蘿蔔素、硒酵母、焦磷酸鐵、硫酸鋅、蔗糖素(甜味劑)、維生素A粉末、檸檬酸、本多酸鈣、維生素D3、葡萄糖酸錳、菸鹼醯胺、維生素B12、生物素、維生素B1、維生素B6、維生素B2、維生素K1、葉酸、碘化鉀。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝密寶養素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1680G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>高能量濃縮營養，有助強身健體</li>\n  <li>美國雙蛋白添加，肌肉生長所需</li>\n  <li>高鈣配方+維生素D3,有助肌肉及骨骼健康</li>\n  <li>高鐵+維生素C，有助紅細胞生成</li>\n  <li>芽孢菌+蔓越莓，改善悶騷癢!</li>\n  <li>好消化吸收，調節生理機能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>長期久坐或臥床女性、產前產後或病後補養、無法正常吞嚥或咀嚼者、減少糖分者、營養補給</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以7平匙奶粉勺+250毫升溫開水沖泡</br>每杯提供248大卡，可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分離蛋白、麥芽糊精、椰子油粉、蔓越莓果汁粉、棕櫚油粉、菊糖、氯化鉀、碳酸鈣、重酒石酸膽鹼、硫酸銅(麥芽糊精、硫酸銅)、氯化鈉、氧化鎂、香料、濃縮乳清蛋白粉、乾酪素鈣、關華豆膠、磷酸鈣、抗壞血酸鈉、維生素E、鉬酵母、硫酸鋅、中鏈三酸甘油酯、鉻酵母、硒酵母、焦磷酸鐵、生物素、菸鹼醯胺、本多酸鈣、維生素A粉末、葡萄糖酸錳、蔗糖素(甜味劑)、維生素B12、芽孢乳酸菌、維生素D3、維生素B6、維生素B1、維生素B2、維生素K1、蔓越莓萃取物、葉酸、碘化鉀。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["綜合高蛋白"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>80% 高蛋白，肌肉生長所需，促進食慾</li>\n  <li>紐西蘭+美國優選蛋白，含22種胺基酸</li>\n  <li>高鈣+D3，有助肌肉及骨骼健康</li>\n  <li>3重鈣源添加, 有助骨骼和牙齒正常發育</li>\n  <li>好消化吸收，調節生理機能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病後補養、需醣類正常代謝者、嗜甜者、運動健身族、營養補給</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以4平匙奶粉勺+200毫升溫開水沖泡</br>可搭配早餐或作為點心、睡前飲品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>濃縮乳清蛋白、大豆分離蛋白、乾酪素鈣、碳酸鈣、麥芽糊精、關華豆膠、磷酸鈣、氯化鉀、氯化鈉、香料、抗壞血酸鈉、維生素E、氧化鎂、菸鹼醯胺、蔗糖素(甜味劑)、硫酸鋅、本多酸鈣、維生素A粉末、生物素、維生素B6、維生素D3、維生素B1、維生素B2、維生素K1、葉酸</p>\n"])}
        }
      ]
    }
  })
}