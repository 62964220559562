export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "cn",
    "resource": {
      "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规格"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议售价"])},
      "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["种类"])},
      "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特色"])},
      "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["适用对象"])},
      "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用建议"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成分"])},
      "products": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝养素A+"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1904G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>富含高营养素热量, 帮助增强体力</li>\n  <li>参考国人DRIS指标，添加28种营养素</li>\n  <li>100%美国优质双重动植物蛋搭配</li>\n  <li>优质植物性MCT提供热量，营养补给</li>\n  <li>高维生素C含量, 有助促进铁吸收</li>\n  <li>高铁配方，有助正常红血球生成</li>\n  <li>添加膳食纤维+益生元，促进肠道蠕动</li>\n  <li>好消化吸收，调节生理机能</li>\n  <li>不含乳糖、蔗糖</li>\n  <li>渗透压294Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>中老年人、病后补养、无法正常进食者、饮食不均、乳糖不耐者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，每杯提供251大卡，以7平匙奶粉勺+200毫升温开水冲泡</br>可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麦芽煳精、大豆分离蛋白、椰子油粉(含中链三酸甘油酯)、棕榈油粉、菊糖、氯化钾、碳酸钙、重酒石酸胆硷、硫酸铜(麦芽煳精、硫酸铜)、氯化钠、香料、氧化镁、浓缩乳清蛋白、乾酪素钙、关华豆胶、磷酸钙、抗坏血酸钠、维生素E、钼酵母、硫酸锌、中链三酸甘油酯、铬酵母、硒酵母、焦磷酸铁、生物素、菸硷醯胺、本多酸钙、维生素A粉末、葡萄糖酸锰、蔗糖素(甜味剂)、维生素B12、维生素D3、维生素B6、维生素B1、维生素B2、维生素K1、叶酸、碘化钾</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铬宝养素(燕麦味)"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1824G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>三大营养素符合国人DRIS范围</li>\n  <li>8种完整B群+铬，维持醣类代谢力!</li>\n  <li>每日2杯补充172微克铬，有助维持醣类正常代谢</li>\n  <li>4大草本精华:金盏花(含叶黄素)、武靴叶、葫芦巴、纳豆萃取(含纳豆激酶)，调整体质</li>\n  <li>高纤+益生元，促进肠道蠕动帮助消化</li>\n  <li>双重蛋白添加，有助肌肉生长</li>\n  <li>好消化吸收，调节生理机能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n  <li>渗透压321Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病后补养、需醣类正常代谢者、嗜甜者、运动健身族、营养补给</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以8平匙奶粉勺+200毫升温开水冲泡</br>每杯提供254大卡与4.1克纤维，可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分离蛋白、麦芽煳精、椰子油粉(含中链三酸甘油酯)、燕麦粉、棕榈油粉、菊糖、燕麦纤维、氯化钾、碳酸钙、磷酸钙、重酒石酸胆硷、硫酸铜(麦芽煳精、硫酸铜)、氯化钠、香料、氧化镁、浓缩乳清蛋白粉、乾酪素钙、关华豆胶、抗坏血酸钠、铬酵母、柠檬酸钠、维生素E、中链三酸甘油酯、纳豆萃取物(含纳豆激酶)、钼酵母、硫酸锌、武靴叶萃取物、硒酵母、焦磷酸铁、葫芦巴萃取物、生物素、菸硷醯胺、本多酸钙、维生素A粉末、葡萄糖酸锰、蔗糖素(甜味剂)、维生素B12、维生素D3、维生素B6、维生素B1、维生素B2、吡啶甲酸铬、金盏花萃取物(含叶黄素)、维生素K1、叶酸、碘化钾、难消化性麦芽煳精、菸硷酸铬。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铬宝养素(无糖)"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1710G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>三大营养素符合国人DRIS范围</li>\n  <li>8种完整B群+铬，维持醣类代谢力!</li>\n  <li>每日2杯补充172微克铬，有助维持醣类正常代谢</li>\n  <li>4大草本精华:金盏花(含叶黄素)、武靴叶、葫芦巴、纳豆萃取(含纳豆激酶)，调整体质</li>\n  <li>高纤+益生元，促进肠道蠕动帮助消化</li>\n  <li>双重蛋白添加，有助肌肉生长</li>\n  <li>好消化吸收，调节生理机能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n  <li>渗透压321Osm/公斤</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病后补养、需醣类正常代谢者、嗜甜者、运动健身族、营养补给</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日2杯，以8平匙奶粉勺+200毫升温开水冲泡</br>每杯提供254大卡与4.1克纤维，可搭配早餐或作为点心、睡前饮品\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分离蛋白、麦芽煳精、椰子油粉(含中链三酸甘油酯)、燕麦粉、棕榈油粉、菊糖、燕麦纤维、氯化钾、碳酸钙、磷酸钙、重酒石酸胆硷、硫酸铜(麦芽煳精、硫酸铜)、氯化钠、香料、氧化镁、浓缩乳清蛋白粉、乾酪素钙、关华豆胶、抗坏血酸钠、铬酵母、柠檬酸钠、维生素E、中链三酸甘油酯、纳豆萃取物(含纳豆激酶)、钼酵母、硫酸锌、武靴叶萃取物、硒酵母、焦磷酸铁、葫芦巴萃取物、生物素、菸硷醯胺、本多酸钙、维生素A粉末、葡萄糖酸锰、蔗糖素(甜味剂)、维生素B12、维生素D3、维生素B6、维生素B1、维生素B2、吡啶甲酸铬、金盏花萃取物(含叶黄素)、维生素K1、叶酸、碘化钾、难消化性麦芽煳精、菸硷酸铬。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["慎养素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>慎选2号-慎养关键体力</li>\n  <li>高密度热量，每杯提供128大卡</li>\n  <li>双优质蛋白:18%蛋白刚刚好，减少含氮废物排出</li>\n  <li>慎养配方:低钠、磷、钾管理, 少负担</li>\n  <li>高铁+叶酸,有助红细胞正常生成</li>\n  <li>添加6%高纤配方, 调节消化道机能</li>\n  <li>添加天然酵母铬, 同步有助维持醣类正常代谢</li>\n  <li>牛磺酸及精胺酸: 调节生理机能，精神旺盛</li>\n  <li>无添加乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>需低钠摄取者、病后补养、水份控制者、蛋白质补充者、增强体力、难正常进食者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日1-3杯，以3平匙奶粉勺+100毫升温开水冲泡</br>每杯提供128大卡与4.1克纤维，可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麦芽煳精、大豆分离蛋白、浓缩乳清蛋白、菊糖、燕麦纤维、碳酸钙、重酒石酸胆硷、磷酸钙、香料、硫酸铜(麦芽煳精、硫酸铜)、氧化镁、氯化钾、抗坏血酸钠、牛磺酸、维生素E、铬酵母、钼酵母、硫酸锌、生物素、硒酵母、焦磷酸铁、蔗糖素(甜味剂)、葡萄糖酸锰、菸硷醯胺、本多酸钙、维生素A粉末、维生素B12、维生素B6、维生素D3、精胺酸、维生素B2、维生素B1、维生素K1、叶酸、碘化钾。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3D蛋白素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>谨慎1号 3D配方,调整体质</li>\n  <li>10% 双优质蛋白质补充，氮平衡</li>\n  <li>慎选低钠磷钾，管理电解质，减少成积</li>\n  <li>添加MCT优质脂肪，提供高密度能量</li>\n  <li>高铁配方有助正常红细胞生成</li>\n  <li>慎利加乘: OPC+南瓜籽+蔓越莓+叶黄素，调节生理机能</li>\n  <li>天然铬酵母，有助维持醣类正常代谢</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>蛋白质摄取调整、病后补养、低钠饮食、增强体力、咀嚼能力差者</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以5平匙奶粉勺+250毫升温开水冲泡</br>每杯提供215大卡，可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、麦芽煳精、棕榈油粉、椰子油粉(含中链三酸甘油酯)、浓缩乳清蛋白、大豆分离蛋白、菊糖、燕麦纤维、碳酸钙、重酒石酸胆硷、香料、氧化镁、抗坏血酸钠、葡萄籽萃取物、牛磺酸、蔓越莓萃取物、肌醇、南瓜籽萃取物、维生素E、焦磷酸铁、钼酵母、硫酸锌、铬酵母、β-胡萝蔔素、硒酵母、菸硷醯胺、蔗糖素(甜味剂)、生物素、维生素A粉末、本多酸钙、葡萄糖酸锰、维生素D3、硫酸铜、维生素B12、维生素B6、维生素B1、维生素B2、维生素K1、合成番茄红素、金盏花萃取物(含叶黄素)、叶酸、碘化钾。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["疗养素A"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["900G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>高密度高质量营养,2杯=1/4每日热量</li>\n  <li>35种维生素+矿物质，均衡营养配方</li>\n  <li>3重蛋白添加，好消化吸收，有助肌肉生长</li>\n  <li>5倍麸醯胺酸，促进食慾，调整体质</li>\n  <li>添加锌+维生素C，有助黏膜修復与伤口癒合</li>\n  <li>牛磺酸+精胺酸，增强体力，促进新陈代谢</li>\n  <li>燕麦纤维+菊糖，促进肠道蠕动帮助消化</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>体力虚弱者、产后或术后者、食慾不佳者、咀嚼吞嚥困难者、营养补给</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以5平匙奶粉勺+250毫升温开水冲泡</br>每杯提供192大卡，可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>麦芽煳精、棕榈油粉、椰子油粉(含中链三酸甘油酯)、大豆分离蛋白、麸醯胺酸、芥花油粉、菊糖、燕麦纤维、牛初乳、精胺酸、氯化钾、碳酸钙、磷酸钙、关华豆胶、重酒石酸胆硷、香料、浓缩乳清蛋白、乾酪素钙、牛磺酸、硫酸铜(麦芽煳精、硫酸铜)、中链三酸甘油酯、抗坏血酸钠、维生素E、氧化镁、钼酵母、铬酵母、β-胡萝蔔素、硒酵母、焦磷酸铁、硫酸锌、蔗糖素(甜味剂)、维生素A粉末、柠檬酸、本多酸钙、维生素D3、葡萄糖酸锰、菸硷醯胺、维生素B12、生物素、维生素B1、维生素B6、维生素B2、维生素K1、叶酸、碘化钾。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宝密宝养素"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1680G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>高能量浓缩营养，有助强身健体</li>\n  <li>美国双蛋白添加，肌肉生长所需</li>\n  <li>高钙配方+维生素D3,有助肌肉及骨骼健康</li>\n  <li>高铁+维生素C，有助红细胞生成</li>\n  <li>芽孢菌+蔓越莓，改善闷骚痒!</li>\n  <li>好消化吸收，调节生理机能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>长期久坐或卧床女性、产前产后或病后补养、无法正常吞嚥或咀嚼者、减少糖分者、营养补给</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以7平匙奶粉勺+250毫升温开水冲泡</br>每杯提供248大卡，可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>芥花油粉、大豆分离蛋白、麦芽煳精、椰子油粉、蔓越莓果汁粉、棕榈油粉、菊糖、氯化钾、碳酸钙、重酒石酸胆硷、硫酸铜(麦芽煳精、硫酸铜)、氯化钠、氧化镁、香料、浓缩乳清蛋白粉、乾酪素钙、关华豆胶、磷酸钙、抗坏血酸钠、维生素E、钼酵母、硫酸锌、中链三酸甘油酯、铬酵母、硒酵母、焦磷酸铁、生物素、菸硷醯胺、本多酸钙、维生素A粉末、葡萄糖酸锰、蔗糖素(甜味剂)、维生素B12、芽孢乳酸菌、维生素D3、维生素B6、维生素B1、维生素B2、维生素K1、蔓越莓萃取物、叶酸、碘化钾。</p>\n"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["综合高蛋白"])},
          "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奶素"])},
          "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["850 NTD"])},
          "specification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["500G/罐"])},
          "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul>\n  <li>80% 高蛋白，肌肉生长所需，促进食慾</li>\n  <li>纽西兰+美国优选蛋白，含22种胺基酸</li>\n  <li>高钙+D3，有助肌肉及骨骼健康</li>\n  <li>3重钙源添加, 有助骨骼和牙齿正常发育</li>\n  <li>好消化吸收，调节生理机能</li>\n  <li>不含乳糖、蔗糖、果糖</li>\n</ul>\n"])},
          "suitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>病后补养、需醣类正常代谢者、嗜甜者、运动健身族、营养补给</p>\n"])},
          "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>每日2杯，以4平匙奶粉勺+200毫升温开水冲泡</br>可搭配早餐或作为点心、睡前饮品</p>\n"])},
          "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>浓缩乳清蛋白、大豆分离蛋白、乾酪素钙、碳酸钙、麦芽煳精、关华豆胶、磷酸钙、氯化钾、氯化钠、香料、抗坏血酸钠、维生素E、氧化镁、菸硷醯胺、蔗糖素(甜味剂)、硫酸锌、本多酸钙、维生素A粉末、生物素、维生素B6、维生素D3、维生素B1、维生素B2、维生素K1、叶酸</p>\n"])}
        }
      ]
    }
  })
}