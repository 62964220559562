import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import NewsIndexView from '../views/NewsIndexView.vue'
import NewstView from '../views/NewsView.vue'
import ContactView from '../views/ContactView.vue'
import ProductIndexView from '../views/ProductIndexView.vue'
import ProductView from '../views/ProductView.vue'
import ServiceView from '../views/ServiceView.vue'
import HealthEducationView from '../views/HealthEducationView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      name: 'nav.home',
      breadcrumbs: () => {
        return [
        ]
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    meta: {
      name: 'nav.about_us',
      breadcrumbs: () => {
        return [
         
          {
            label: 'nav.about_us'
          }
        ]
      }
    }
  },
  {
    path: '/news',
    component: NewsIndexView,
    children: [
     
    ],
    meta: {
      name: 'nav.news',
      breadcrumbs: () => {
        return [
         
          {
            label: 'nav.news'
          }
        ]
      }
    }
  },
  {
    path: '/news/:name',
    component: NewstView,
    meta: {
      name: 'nav.news',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.news',
            route: '/news'
          },
          {
            label: 'news.'+route.params.name+'.title'
          }
        ]
      }
    }
  },
  {
    path: '/contact',
    component: ContactView,
    meta: {
      name: 'nav.contact_us',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.contact_us',
          }
        ]
      }
    }
  },
  {
    path: '/products',
    component: ProductIndexView,
    meta: {
      name: 'nav.products',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.products',
          }
        ]
      }
    }
  },
  {
    path: '/products/:category',
    component: ProductIndexView,
    meta: {
      name: 'nav.products',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.products',
            route: '/products',
          },
          {
            label: 'products_categories.'+route.params.category
          }
        ]
      }
    }
  },
  {
    path: '/products/:id(\\d+)',
    component: ProductView,
    meta: {
      name: 'nav.products',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.products',
            route: '/products',
          },
          {
            label: 'products.'+route.params.id + '.name'
          }
        ]
      }
    }
  },
  {
    path: '/services',
    component: ServiceView,
    meta: {
      name: 'nav.services',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.services'
          }
        ]
      }
    }
  },
  {
    path: '/health-education/:id',
    component: HealthEducationView,
    meta: {
      toTop: true,
      name: 'nav.health-education',
      breadcrumbs: (route) => {
        return [
          {
            label: 'nav.health-education'
          }
        ]
      }
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.toTop) {
      return { top: 0, behavior: 'smooth' };
    }
    return savedPosition;
  }
})

export default router
