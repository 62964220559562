<template>
  <div class="news flex flex-wrap">
    <div class="col-12 md:col-8 lg:col-9 flex flex-wrap align-items-center justify-content-between grid-nogutter">
      <div class="col-12">
        <Card class="col-12">
          <template #header>
              <img alt="" :src="news.imageUrl" />
          </template>
          <template #title> {{ $t('news.' + $route.params.name + '.title')}}</template>
          <template #subtitle>  {{ $t('news.' + $route.params.name + '.category')}} </template>            
          <template #content> <div v-html="$t('news.' + $route.params.name + '.content')"></div></template>
        </Card>
      </div>
    </div>
    <div class="col-12 md:col-4 lg:col-3 flex">
      <div class="latest-updates col-12">
        <h3>{{ $t('latest_updates') }}</h3>
        <div class="items flex flex-column">
          <div class="item flex" v-for="(n, i) in newsList.slice(-4).reverse()" :key="i">
            <div class="image w-6rem">
              <img :src="n.imageUrl" alt="">
            </div>
            <div class="info">
              <p>{{ n.date }}</p>
              <p><router-link :to="`/news/${n.id}`">{{ $t('news.' + n.id + '.title') }}</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from 'primevue/card';
import { computed } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
const store = useStore();
const route = useRoute();
const newsList = computed(() => store.getters['news/data'])

const news = store.getters['news/get'](route.params.name)

console.log(news)

</script>

<style lang="scss" scoped>
.news {
  img {
    width: 100%;
  }
}

.latest-updates {
  h3 {
    &:before {
      content: "";
      position: relative;
      height: 2px;
      border-radius: 8px;
      width: 40px;
      left: 12px;
      bottom: -43px;
      background-color: var(--bg-color);
      display: block;
    }
    &:after{
      position: relative;
      height: 6px;
      width: 6px;
      background-color: var(--bg-color);
      left: 0;
      bottom: -13px;
      content: "";
      border-radius: 50%;
      display: block;
    }
  }
  .item {
    margin: 1rem 0;
    .image {
      margin: 0 8px;
      img {
        border-radius: 5px;
      }
    }
    
    .info {
    p {
      margin: 8px 0;
    }
  }
  }
  
}
</style>