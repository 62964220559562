export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "tw",
    "resource": {
      "Do you have the following questions?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有以下問題嗎？"])},
      "Actually, you might be experiencing these issues with your diet...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其實你可能在飲食出現了這些問題..."])},
      "Program details.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方案內容"])},
      "service": {
        "book_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/book_now.webp"])},
        "problems": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/problem_1.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/problem_2.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/problem_3.webp"])}
        ],
        "services": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/service_1.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/service_2.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/service_3.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/service_4.webp"])}
        ],
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/tw/contact_us.webp"])},
        "members": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["董彥廷"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["營養師"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/don.webp"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臺北醫學大學保健營養所碩士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofit線上減重班 營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞東醫院形體美容中心 合作營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山東孝養院老年養護機構 營養總監"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金穎生物科技 產品經理/營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知名上市企業 產品開發總監/營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機能食品與營養講座 企業特聘講師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紐特普健康科技 機能性食品開發顧問"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣高考營養師合格證書"])}
            ]
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["陳品言"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/chen.webp"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["營養師"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國立海洋大學食品科學 碩士"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中山醫學大學 學士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紐特普健康科技 創辨人"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度譜科技腫瘤病患營養顧問"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海肺科醫院特聘講師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TCI大江生醫 首席講師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海百岳特 產品開發總監"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾生物科技 研發總監"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前台中榮總營養科研究員"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣高考營養師合格證書"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣高考食品技師合格證書"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HACCP 稽核員"])}
            ]
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["謝宗海"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/xie.webp"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["營養師"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國立台灣大學食品科技所 碩士"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["義守大學 醫學營養學系 學士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金穎生物科技 行銷企劃/營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知名營養品公司 產品研發/營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["醫院與社區疾病營養衛教 特聘講師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亞東醫院形體美容中心 合作營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["線上減重減脂團班 營養師"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣高考營養師合格證書"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣保健食品初級工程師"])}
            ]
          }
        ]
      }
    }
  })
}