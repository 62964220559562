import { createStore } from 'vuex'
import news from './modules/news'
import products from './modules/products'
import axios from 'axios';


const state = () => ({
  city: '',
  country: '',
  ip: ''
})

const mutations = {
  setIPInfo(state, newData) {
    state.country = newData.country
    state.city = newData.city
    state.ip = newData.ip
    console.log(state)
  }
}

const actions = {
  fetchIPInfo({ commit }) {
    return axios.get('https://ipapi.co/json/')
      .then(response => {
        commit('setIPInfo', response.data);
      })
      .catch(error => {
        commit('setIPInfo', {
          country: 'TW'
        });
      });
  }
}

export default createStore({
  modules: {
    news,
    products
  },
  state,
  mutations,
  actions
})
