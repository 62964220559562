<template>
  <div>
    <Carousel :value="ads" :numVisible="1" :numScroll="1" :autoplayInterval="2000" :circular="true">
    <template #item="slotProps">
        <div class="col-12">
            <img class="ad-img" :src="slotProps.data.imageUrl" alt="">
        </div>
    </template>
  </Carousel>  
  <div class="col-10 col-offset-1">
    <h1>產品系列</h1>
    <Products></Products>
    <HealthEducation></HealthEducation>
  </div>
  
  </div>
  
</template>

<script setup>
import Carousel from 'primevue/carousel';

import { ref } from "vue";
import { useRouter } from "vue-router";
import Products from '@/components/Products.vue';
import HealthEducation from '@/components/HealthEducation.vue';

const ads = ref([
  {
    imageUrl: '/images/1.webp'
  },
  {
    imageUrl: '/images/2.webp'
  },
  {
    imageUrl: '/images/3.webp'
  },
  {
    imageUrl: '/images/51704699122_.pic.webp'
  }
])
</script>

<style scoped lang="scss">
.p-carousel {
  position: relative;
  z-index: -1;
}
.ad-img {
  width: 100%;
}
</style>
