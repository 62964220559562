<i18n locale="tw" lang="yml" src="@/locales/products/tw.yml"> 
</i18n>
<i18n locale="cn" lang="yml" src="@/locales/products/cn.yml"> 
</i18n>
<template>
  <div>
    <div class="categories" v-for="(c, i) in categories" :key="i">
      <div v-if="!$route.params.category || c.name == $route.params.category">
        <h2 class="title">
        {{ t('products_categories.' + c.name) }}
        </h2>
        <div class="flex flex-wrap">
          <div v-for="(p, i) in grouped[c.name]" :key="i" class="col-12 md:col-6 lg:col-4 xl:col-3">
            <router-link :to="`/products/${p.id}`">
              <div class="product flex flex-wrap align-items-center justify-content-center p-4">
                <img :src="p.imageUrl" alt="">
                <p>{{ t('products.' + p.id + '.name') }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})
const route = useRoute();
const store = useStore();
const categories =  computed(() => store.state.products.categories)
const products = computed(() => {
  if(route.params.category)
    return store.getters['products/getProductsByCategory'](route.params.category)
  return store.getters['products/products']
})

const grouped = computed(() => store.getters['products/groupByCategory'])

</script>

<style scoped lang="scss">
.product {
  img {
    width: 100%;
  }
  p {
    text-align: center;
  }
}
.categories {
  .title {
    display: inline-block;
    &::after{
      content: "";
      position: relative;
      width: 100%;
      height: 10px;
      background-color: var(--bg-color);
      display: block;
      bottom: 10px;
      z-index: -1;
    }
  }
}
</style>