import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import i18n from './i18n'

import '@/assets/sass/main.sass'
import '@/assets/sass/themes/mytheme/theme.scss'
import 'primeicons/primeicons.css'

const app = createApp(App)
app.use(PrimeVue)
app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')
console.log(app.config.globalProperties.$i18n);

// store.dispatch('fetchIPInfo');
