<template>
  <div class="header col-12 ">
    <div class="col-10 col-offset-1 flex flex-warp justify-content-between">
      <h1 v-if="title" class="flex h-title">{{ $t(title) }}</h1>
      <Breadcrumb v-if="$route.meta.breadcrumbs" :home="home" :model="$route.meta.breadcrumbs($route)" class="flex">
        <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                    <span :class="[item.icon, 'text-color']" />
                    <span v-if="item.label" class="text-primary font-semibold">{{ $t(item.label) }}</span>
                </a>
            </router-link>
            <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-color">{{ $t(item.label) }}</span>
            </a>
        </template>
      </Breadcrumb>
    </div>
    
  </div>
</template>

<script setup>
import Breadcrumb from 'primevue/breadcrumb';
import { ref,onMounted } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute()
defineProps(['title'])

const home = ref({
    icon: 'pi pi-home',
    route: '/'
});

const items = ref([
    { label: 'Components' }, 
    { label: 'Form' }, 
    { label: 'InputText', route: '/inputtext' }
]);

onMounted(() => {
  console.log(route.matched)
})
</script>

<style scoped lang="scss">
.h-title {
  color: var(--deep-cinnamon-red);
}
.header {
  background-color: var(--medium-silver);
  padding: 50px 0 !important;
  margin-top: -35px;
  z-index: -50;
}
</style>