<template>
  <div class="flex flex-wrap">
    <div class="categories flex flex-column col-12 md:col-4 align-items-center justify-content-center p-1" v-for="(c, i) in categories" :key="i">
      <router-link :to="`/products/${c.name}`">
        <div class="hover:shadow-1 container flex flex-column align-items-center justify-content-center p-3">
          <img :src="grouped[c.name][0].imageUrl" alt="">
          <h1>{{ t('products_categories.' + c.name) }}</h1>
        </div>
      </router-link>
    </div>
  </div>

</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n'
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const store = useStore();
const categories =  computed(() => store.state.products.categories)
const grouped = computed(() => store.getters['products/groupByCategory'])
</script>

<style scoped lang="scss">
.categories {
  .container {
    img {
      width: 100%;
    }
    
    &:hover{
      // background-color: #f9f9f9;
      color: var(--soft-gold);
      a {
        border: 1px solid var(--soft-gold);
      }
    }
    // background-color: var(--primary-color);
  }
  
}
</style>