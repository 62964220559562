<template>
  <div class="line">
    <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=taho">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256">
        <g fill="#1ca739" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M9,4c-2.76,0 -5,2.24 -5,5v32c0,2.76 2.24,5 5,5h32c2.76,0 5,-2.24 5,-5v-32c0,-2.76 -2.24,-5 -5,-5zM25,11c8.27,0 15,5.35922 15,11.94922c0,2.63 -1.0407,5.01156 -3.2207,7.35156c-1.57,1.78 -4.11875,3.73938 -6.46875,5.35938c-2.35,1.6 -4.51055,2.85945 -5.31055,3.18945c-0.32,0.13 -0.56,0.18945 -0.75,0.18945c-0.66,0 -0.60078,-0.69828 -0.55078,-0.98828c0.04,-0.22 0.2207,-1.26172 0.2207,-1.26172c0.05,-0.37 0.09922,-0.95813 -0.05078,-1.32812c-0.17,-0.41 -0.84008,-0.6207 -1.33008,-0.7207c-7.2,-0.94 -12.53906,-5.89101 -12.53906,-11.79102c0,-6.59 6.73,-11.94922 15,-11.94922zM23.99219,18.99805c-0.50381,0.00935 -0.99219,0.39383 -0.99219,1.00195v6c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1v-2.87891l2.18555,3.45898c0.566,0.792 1.81445,0.39292 1.81445,-0.58008v-6c0,-0.552 -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1v3l-2.18555,-3.58008c-0.21225,-0.297 -0.51998,-0.42748 -0.82227,-0.42187zM15,19c-0.552,0 -1,0.448 -1,1v6c0,0.552 0.448,1 1,1h3c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1h-2v-5c0,-0.552 -0.448,-1 -1,-1zM21,19c-0.552,0 -1,0.448 -1,1v6c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1v-6c0,-0.552 -0.448,-1 -1,-1zM31,19c-0.552,0 -1,0.448 -1,1v6c0,0.552 0.448,1 1,1h3c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1h-2v-1h2c0.553,0 1,-0.448 1,-1c0,-0.552 -0.447,-1 -1,-1h-2v-1h2c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1z"></path></g></g>
      </svg>
    </a>
  </div>
</template>

<style scoped lang="scss">
.line {
  position: fixed;
  bottom: 20%;
  right: 0;
  color: green;
}
</style>