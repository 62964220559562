<template>
  <div class="contact_us flex flex-wrap">
    <div class="list col-12 flex flex-wrap">
      <div class="card flex col-12 md:col-6 lg:col-3 address">
        <div class="p-4 flex col-12 flex-column align-items-center justify-content-start">
          <i class="pi p-5 border-circle pi-map-marker contact-icon" style=""></i> 
          <h3>{{ $t('address') }}</h3>
          <a href="https://www.google.com/maps?ll=24.13494,120.636597&z=15&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=13315750471691712625">{{ $t('info.address')}}</a>
        </div>
      </div>
      <div class="card flex col-12 md:col-6 lg:col-3 business-hours">
        <div class="p-4 flex col-12 flex-column align-items-center justify-content-start">
          <i class="pi p-5 border-circle pi-clock lign-items-center justify-content-center contact-icon" style=""></i>
          <h3>{{ $t('business_hours.name') }}</h3>
          <p>{{ $t('business_hours.week') }}</p>
          <p>8:30am - 5:00pm</p>
        </div>
      </div>
      <div class="card flex col-12 md:col-6 lg:col-3 email">
        <div class="p-4 flex col-12 flex-column align-items-center justify-content-start">
          <i class="pi p-5 border-circle pi-envelope lign-items-center justify-content-center contact-icon" style=""></i>
          <h3>{{ $t('email') }}</h3>
          <a :href="`mailto:${$t('info.email')}`">{{ $t('info.email') }}</a>
        </div>
      </div>
      <div class="card flex col-12 md:col-6 lg:col-3 telephone">
        <div class="p-4 flex col-12 flex-column align-items-center justify-content-start">
          <i class="pi p-5 border-circle pi-phone lign-items-center justify-content-center contact-icon" style=""></i>
          <h3>{{ $t('telephone') }}</h3>
          <a :href="`tel:${$t('info.telephone')}`">{{ $t('info.telephone') }}</a>
        </div>
      </div>
    </div>
    <div class="map col-12">
      <iframe loading="lazy" class="px-3" style="border:0;" src="https://maps.google.com/maps?q=%E5%A4%A7%E7%A6%BE%E7%94%9F%E7%89%A9%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="大禾生物科技有限公司" aria-label="大禾生物科技有限公司"></iframe>
    </div>
  </div>
</template>
<script setup>
</script>

<style lang="scss" scoped>
.card {
  > div {
    height: 100%;
  }
}
.map{
  overflow:hidden;
  padding-bottom:40.25%;
  position:relative;
  height:0;
  margin-bottom: 20px;
  iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}

.pi {
  background-color: var(--primary-color);
}

.contact-icon {
  font-size: 2.5rem;
  color: var(--soft-gold);
}

.contact_us {
  .list {
    .card {
      .pi {
        background-color: var(--light-silver);
      }
      div {
        background-color: #f9f9f9;
      }
    }
  }
}
</style>