<template>
    <div class="flex flex-wrap align-items-center justify-content-between">
      <div class="col-12 md:col-6 lg:col-4 xl:col-3" v-for="(n, i) in news" :key="i">
        <Card class="flex flex-wrap mx-3 my-5">
            <template #header>
                <img alt="" :src="n.imageUrl" />
            </template>
            <template #title> <router-link :to="`/news/${i}`">{{ $t('news.' + i + '.title')}}</router-link> </template>
            <template #subtitle>  {{ $t('news.' + i + '.category')}} </template>            
        </Card>
      </div>
    </div>

</template>

<script setup>
import Card from 'primevue/card';
import { computed } from 'vue'
import { useStore } from 'vuex';
const store = useStore();
const news = store.state.news.data

const langs = computed(() => store.getters['news/i18n'])


</script>

<style lang="scss" scoped>

</style>