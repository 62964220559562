const state = () => ({
  data: [
    {
        "name": "exhibition",
        "date": "2023-01-01",
        "imageUrl": "https://placehold.co/370x250",
        "title": {
            "en": "Exhibition",
            "cn": "南港展览馆开展啰！",
            "tw": "南港展覽館開展囉！"
        },
        "category": {
            "en": "Exhibition News",
            "cn": "展场消息",
            "tw": "展场消息"
        },
        "content": {
            "en": "<p>Event period: From now until 2024/2/2<br>Event description:</p><ul><li>During the event, purchase 2 cans of brewed drinks in a single purchase and receive a Lover's Card-style universal tissue pack.</li><li>For a single purchase of 6 cans, receive either a COOKTONE non-stick double-ear covered soup pot (24cm) or an Italian La Belle graphene water-washed sleep pillow.</li><li>Gifts are based on actual items, and the quantity depends on the inventory of each store, limited quantity, while stocks last.</li><li>For purchase details, please refer to physical distribution points or inquire by phone at 0800-222-777.</li></ul>",
            "cn": "<p>活动期间：即日起-2024/2/2<br>活动说明：</p><ul><li>活动期间单次购买冲泡饮品2罐赠送情人牌取式万用纸巾1袋，</li><li>单次购买6罐赠送COOKTONE麦饭石不沾双耳附盖汤锅(24公分)1个或意大利La Belle石墨烯水洗舒眠枕1个</li><li>赠品以实物为主，数量依各门市库存为主，数量有限换完为止</li><li>购买地点详至实体经销据点或来电查询0800-222-777</li></ul>",
            "tw": "<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222-777</li></ul>"
        }
    },
    {
        "name": "金聪明",
        "date": "2023-01-01",
        "imageUrl": "https://placehold.co/370x250",
        "title": {
            "en": "Smart Gold",
            "cn": "金聪明",
            "tw": "金聰明"
        },
        "category": {
            "en": "Product News",
            "cn": "产品消息",
            "tw": "產品消息"
        },
        "content": {
            "en": "<p>Event period: From now until 2024/2/2<br>Event description:</p><ul><li>During the event, purchase 2 cans of brewed drinks in a single purchase and receive a Lover's Card-style universal tissue pack.</li><li>For a single purchase of 6 cans, receive either a COOKTONE non-stick double-ear covered soup pot (24cm) or an Italian La Belle graphene water-washed sleep pillow.</li><li>Gifts are based on actual items, and the quantity depends on the inventory of each store, limited quantity, while stocks last.</li><li>For purchase details, please refer to physical distribution points or inquire by phone at 0800-222-777.</li></ul>",
            "cn": "<p>活动期间：即日起-2024/2/2<br>活动说明：</p><ul><li>活动期间单次购买冲泡饮品2罐赠送情人牌取式万用纸巾1袋，</li><li>单次购买6罐赠送COOKTONE麦饭石不沾双耳附盖汤锅(24公分)1个或意大利La Belle石墨烯水洗舒眠枕1个</li><li>赠品以实物为主，数量依各门市库存为主，数量有限换完为止</li><li>购买地点详至实体经销据点或来电查询0800-222-777</li></ul>",
            "tw": "<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222-777</li></ul>"
        }
    },
    {
        "name": "金宝宝",
        "date": "2023-01-01",
        "imageUrl": "https://placehold.co/370x250",
        "title": {
            "en": "Golden Baby",
            "cn": "金宝宝",
            "tw": "金寶寶"
        },
        "category": {
            "en": "Product News",
            "cn": "产品消息",
            "tw": "產品消息"
        },
        "content": {
            "en": "<p>Event period: From now until 2024/2/2<br>Event description:</p><ul><li>During the event, purchase 2 cans of brewed drinks in a single purchase and receive a Lover's Card-style universal tissue pack.</li><li>For a single purchase of 6 cans, receive either a COOKTONE non-stick double-ear covered soup pot (24cm) or an Italian La Belle graphene water-washed sleep pillow.</li><li>Gifts are based on actual items, and the quantity depends on the inventory of each store, limited quantity, while stocks last.</li><li>For purchase details, please refer to physical distribution points or inquire by phone at 0800-222-777.</li></ul>",
            "cn": "<p>活动期间：即日起-2024/2/2<br>活动说明：</p><ul><li>活动期间单次购买冲泡饮品2罐赠送情人牌取式万用纸巾1袋，</li><li>单次购买6罐赠送COOKTONE麦饭石不沾双耳附盖汤锅(24公分)1个或意大利La Belle石墨烯水洗舒眠枕1个</li><li>赠品以实物为主，数量依各门市库存为主，数量有限换完为止</li><li>购买地点详至实体经销据点或来电查询0800-222-777</li></ul>",
            "tw": "<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222"
      }
    },
    {
      name: '金養生',
      date: '2023-01-01',
      imageUrl: 'https://placehold.co/370x250',
      title: {
        en: '金養生',
        cn: '金養生',
        tw: '金養生'
      },
      category: {
        en: '產品消息',
        cn: '產品消息',
        tw: '產品消息'
      },
      content: {
        en: '<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222-777</li></ul>',
        cn: '<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222-777</li></ul>',
        tw: '<p>活動期間：即日起-2024/2/2<br>活動說明：</p><ul><li>活動期間單次購買沖泡飲品2罐贈送情人牌取式萬用紙巾1袋，</li><li>單次購買6罐贈送COOKTONE麥飯石不沾雙耳附蓋湯鍋(24公分)1個或義大利La Belle石墨烯水洗舒眠枕1個</li><li>贈品以實物為主，數量依各門市庫存為主，數量有限換完為止</li><li>購買地點詳至實體經銷據點或來電查詢0800-222-777</li></ul>',
      }
    },
  ],
})

const getters = {
  i18n: (state) => {
    const i18n = {}
    for(let i = 0; i < state.data.length; i++) {
      let news = state.data[i];
      const {title, content, category} = news
      for(const lang in title) {
        i18n[lang] = i18n[lang] || {news:[]}
        i18n[lang].news[i] = {
          title: title[lang],
          content: content[lang],
          category: category[lang]
        }
      }
    }
    return i18n
  },

  data: (state) => {
    for(let i = 0; i < state.data.length; i++) {
      state.data[i].id = i
    }
    return state.data
  },

  get: (state) => (id) => {
    return state.data[id]
  }
}

export default {
  namespaced: true,
  state,
  getters
}