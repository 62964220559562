<i18n locale="tw" lang="yml" src="@/locales/health-education/tw.yml"> 
</i18n>
<i18n locale="cn" lang="yml" src="@/locales/health-education/cn.yml"> 
</i18n>
<template>
  <div class="flex flex-wrap justify-content-center">
    <div class="flex flex-column title">
      <p class="subtitle">HEALTH EDUCATION</p>
      <h1>營養衛教</h1>
    </div>
    
    <div class="flex flex-wrap justify-content-center align-items-stretch">
      <div v-for="p in posts" :key="p.id" class="container flex col-12 md:col-4">
        <div class="card align-content-between justify-content-between flex flex-warp flex-column shadow-8 p-5 border-round-lg">
          <img class="w-full border-round-lg" :src="t('health-education.' + p.id + '.image')" alt="">
          <h2>{{ t('health-education.' + p.id + '.title') }}</h2>
          <router-link class="p-button font-bold" :to="`health-education/${p.id}`">Read More &nbsp;&nbsp; <i class="pi pi-arrow-right"></i></router-link>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t, rt, tm, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

const posts = [
  {
    id: 0,
    subtitle: '糖尿病',
  },
  {
    id: 5,
    subtitle: '糖尿病',
  },
  {
    id: 10,
    subtitle: '糖尿病',
  },
]
</script>

<style lang="scss" scoped>
.container {
  .card {
    
    a {
      
    }
  }
}
.title {
  text-align: center;
  .subtitle {
    color: var(--gray-500);
    margin-bottom: 0;
  }

  h1 {
    margin-top: 0;
  }
}
</style>