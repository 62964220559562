export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "cn",
    "resource": {
      "about": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾的开始"])},
        "sections": [
          {
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除了广告品牌，今天起..你有更健康、更经济的选择!"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾生技创立于2006年, 有感台湾即将进入高龄社会，慢病、卧床失能、癌症患者营养需求亦逐年攀升，然而所需的营养补充品皆被大牌垄断市场，因此我们成立专业营养研发团队，致力将医学营养普及，让每位消费者能更经济地提升健康与生活品质。\n"])}
          },
          {
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视消费者为家人，<br>所以我更在乎安全和健康！"])},
            "descriptions": [
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好专业Our Exprtise"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">「专业营养师的专属专用营养品」</span>以临床营养为核心，探究消费者营养与健康需求，结合医学、药学、食品领域不断创新研发，针对慢病、癌症、中老年族群等，打造科学、验校的专用营养品，将预防医学落实，精确细心呵护并改善消费者的生活。"])}
              },
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好安心Promise&Assurance"])},
                "content": [
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">研发:</span> 根据科学，不停创新"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">製造:</span> 全球选料，国际工厂"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">检验:</span> 绝对把关，批次检验"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">售后:</span> 千万商险，营养答疑"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製造厂通过国际ISO-900，ISO-22000、HACCP品管"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全自动化氮气充填生产製造设备"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾产物保险公司2000万产品责任险"])}
                ]
              },
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好口碑Good Reputation"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从药局、医疗器材通路到长照、居家照护，透过专业医药师、营养师、护理师的肯定与推荐，并获选工商时报健康专栏「产品、包装、口味」三大好评! 大禾宝养素和铬宝养素等系列出口15个国家，让千千万万使用者不仅获得如家人般的用心的营养与健康补给，同时也能享受到美味口感!"])}
              }
            ]
          }
        ]
      }
    }
  })
}