<i18n locale="tw" lang="yml" src="@/locales/services/tw.yml"> 
</i18n>
<i18n locale="cn" lang="yml" src="@/locales/services/cn.yml"> 
</i18n>

<template>
  <div class="p-2">
    <div class="apply flex flex-wrap">
      <div class="img-container flex col-12 md:col-12">
        <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=taho">
          <img :src="t('service.book_now')" class="" alt="">
        </a>
      </div>
      
      <!-- <div class="content flex flex-column col-12 md:col-5 align-items-center justify-content-center">
        <h1>{{ t('營養師一對一') }}</h1>
        <h1>{{ $t('體態及飲食分析') }}</h1>
        <h3>{{ $t('30分鐘營養師教你吃出易瘦體質') }}</h3>
        <div class="flex flex-wrap justify-content-center">
          <div class="first-apply">
            <span>{{ $t('首次報名') }}</span>
            <span class="price">$599</span>
          </div>
          <div class="original">
            <span>{{ $t('原價') }}</span>
            <span class="price">$2,000</span>
          </div>
        </div>
        <div class="flex apply-now">
          <h1><a href="#"><span>{{ $t('立即報名') }}</span><i class="pi pi-arrow-right" style="font-size: 2rem"></i></a></h1>
        </div>
      </div> -->
  </div>
    <div class="problem-section flex flex-column align-items-center justify-content-center">
      <h1>{{ t('Do you have the following questions?') }}</h1>
      <h3>{{ t('Actually, you might be experiencing these issues with your diet...') }}</h3>
      <div class="problems flex flex-wrap align-items-stretch justify-content-center">
        <div v-for="(v, k) in tm('service.problems')" :key="k" class="container p-1 flex col-12 md:col-4">
          <div class="problem px-5 py-3 flex flex-column align-items-center justify-content-between ">
            <img :src="rt(v)" alt="">
          </div>
        </div>
        <!-- <div class="container p-1 flex flex-column col-12 md:col-4">
          <div class="problem px-5 py-3 flex flex-column align-items-center justify-content-between ">
            <img src="https://placehold.co/200x200" alt="">
            <h3>{{ $t('想吃又不能吃的甜食好痛苦?') }}</h3>
          </div>
        </div>
        <div class="container p-1 flex flex-column col-12 md:col-4">
          <div class="problem px-5 py-3 flex flex-column align-items-center justify-content-between ">
            <img src="https://placehold.co/200x200" alt="">
            <h3>{{ $t('滿江紅的關鍵指標沒起色,怎麼辦?') }}</h3>
          </div>
        </div> -->
      </div>
    </div>
    <div class="plan-section flex flex-column align-items-center justify-content-center">
      <h1>{{ t('Program details.') }}</h1>
      <div class="plans flex flex-wrap align-items-stretch justify-content-center">
        <div v-for="(v, k) in tm('service.services')" :key="k" class="plan flex flex-column align-items-center justify-content-between col-12 m-2 md:col-5">
          <!-- <h2><i class="pi pi-check-circle"></i>{{ $t('個人營養評估與分析報告') }}</h2>
          <h4>subtitle</h4> -->
          <img style="border-radius: 5px;" :src="rt(v)" alt="">
        </div>
        <!-- <div class="plan flex flex-column align-items-center justify-content-between col-12 m-2 md:col-5">
          <h2><i class="pi pi-check-circle"></i>{{ $t('個人營養評估與分析報告') }}</h2>
          <h4>subtitle</h4>
          <img src="https://placehold.co/512x256" alt="">
        </div>
        <div class="plan flex flex-column align-items-center justify-content-between col-12 m-2 md:col-5">
          <h2><i class="pi pi-check-circle"></i>{{ $t('個人餐食設計與餐單') }}</h2>
          <h4>{{ $t('含特殊飲食設計管灌飲食、流質飲食、低鹽飲食、低蛋白點心等') }}</h4>
          <img src="https://placehold.co/512x256" alt="">
        </div>
        <div class="plan flex flex-column align-items-center justify-content-between col-12 m-2 md:col-5">
          <h2><i class="pi pi-check-circle"></i>{{ $t('精準保健規劃(不走歪路)') }}</h2>
          <h4>subtitle</h4>
          <img src="https://placehold.co/512x256" alt="">
        </div>
        <div class="plan flex flex-column align-items-center justify-content-between col-12 m-2 md:col-5">
          <h2><i class="pi pi-check-circle"></i>{{ $t('整合性個人運動作息與護理規劃') }}</h2>
          <h4>subtitle</h4>
          <img src="https://placehold.co/512x256" alt="">
        </div> -->
      </div>
    </div>
    <div class="line-section flex flex-column align-items-center justify-content-center col-12 p-0">
      <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=taho">
        <img style="width: 100%;" :src="t('service.contact_us')" alt="">
      </a>
    </div>
    <div class="lineup-section flex flex-column m-2">
      <Carousel :value="tm('service.members')" :numVisible="1" :numScroll="1" :autoplayInterval="2000" :circular="true">
        <template #item="slotProps">
          <div class="personnel flex flex-wrap align-items-center justify-content-center">
            <div class="avatar col-12 md:col-5">
              <img :src="rt(slotProps.data.photo)" alt="">
            </div>
            <div class="content flex flex-column col-12 md:col-7">
              <div class="title flex flex-row flex-wrap"><h1 class="name">{{ rt(slotProps.data.name) }}</h1> 
                <!-- <div class="job flex align-items-center justify-content-center">
                  <h3 class="">{{ rt(slotProps.data.job) }}</h3>
                </div> -->
              </div>
              <div class="education mx-2 my-5">
                <h1>{{ $t('學歷') }}</h1>
                <p v-for="e in slotProps.data.edu" :key="e">{{rt(e)}}</p>
              </div>
              <div class="experience mx-2 my-5">
                <h1>{{ $t('經歷及相關證照') }}</h1>
                <p v-for="e in slotProps.data.exp" :key="e">{{rt(e)}}</p>
              </div>
            </div>
          </div>
        </template>
      </Carousel>  
    </div>
  </div>
</template>

<script setup>
import Carousel from 'primevue/carousel';
import QrcodeVue from 'qrcode.vue'
import Button from 'primevue/button';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'

const { t, rt, tm, locale } = useI18n({ 
  inheritLocale: true,
  useScope: 'local'
});

const changeLanguage = (lang) => {
  locale.value = lang;
};





// const members = ref([
//   {
//     avatar: 'https://placehold.co/320x320',
//     name: '日照索隆',
//     job: '草帽副船長',
//     edu: [
//       '日照國小向日葵班班長',
//       '日照國中廁所組長'
//     ],
//     exp: [
//       '海賊獵人',
//       '草帽海賊團副船長'
//     ]
//   },
//   {
//     avatar: 'https://placehold.co/320x320',
//     name: '山東D魯夫',
//     job: '草帽船長',
//     edu: [
//       '日照國小玫瑰班班長',
//       '日照國中罰站組組長'
//     ],
//     exp: [
//       '海上水桶',
//       '草帽海賊團船長'
//     ]
//   }
// ])

</script>

<style lang="scss" scoped>


h3 {
  margin: 0;
  color: var(--text-color-secondary);
}
.apply {
  
  .img-container {
    // width: 100%;
   img {
    width: 100%;
   }
  }

  
  .content {
    // text-align: right;
    // padding-top: 120px;
    // padding-left: 50px;
  }

  .first-apply {
    background-color: var(--bg-color);
    border-radius: 20px;
    padding: .5em 20px;
    position: relative;
    padding-right: 160px;
    font-size: 1.17em;
    font-weight: bold;

    > .price {
      position: absolute;
      font-size: 3em;
      top: -20px;
      right: 10px;
      text-shadow: 5px -4px 0px var(--bg-color);
      color: var(--pink-500);
    }

    
  }
  .original {
    padding: .5em;
    > .price {
      text-decoration: line-through;
    }
  }
  .apply-now {
    a {
      background-color: var(--bg-color);
      border-radius: 25px;
      padding: 5px 50px;
    }
    
  }
}

.problem-section {
  .problems {
    .problem {
      // border: 1px solid;
      height: 100%;
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}

.plan-section {
  .plans {
    .plan {
      border-radius: 10px;
      background-color: var(--bg-color);
      h1 {
        i {
          font-size: 1em;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.line-section {
  height: 256px;
  color: var(--gray-50);
  overflow: hidden;
  .line {
    transition: transform 0.3s ease;
    width: 100%;
    height: 100%;
    background-color: black; /* fallback color */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://placehold.co/1024x256");
    background-position: center;
    background-size: cover;
    &:hover {
      transform: scale(1.1);
    }
    a {
      color: var(--gray-50);
      border: 1px solid var(--gray-50)
    }
  }
}

.lineup-section {
  .personnel {
    .avatar {
      img {
        width: 100%;
      }
    }
    .education, .experience {
      border-left: 5px solid var(--bg-color);
      p,h1 {
        margin: 3px 0;
      }
    }
    .title {
      .name {
        font-size: 4em;
      }
      .job {
        padding: 1em;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 5px solid var(--bg-color);
        font-size: 1.5em;
        color: var(--bg-color);
      }
    }
  }
}
</style>