
<i18n lang="yml" locale="tw">

</i18n>

<template>
  <div class="grid-nogutter" @scroll="onScroll">
    <Line></Line>
      <div class="col-12">
        <div class="col-12 lg:col-10 lg:col-offset-1 top flex justify-content-between">
          <div class="logo flex align-items-center justify-content-center">
            <a href="#" >
              <img style="width: 150px;" src="/images/logo.webp" alt="">
            </a>  
          </div>
          <div class="info flex align-items-center justify-content-center gap-3">
            <div class="address flex gap-2">
              <i class="pi pi-map-marker" style="font-size: 2.5rem; color: var(--primary-darker-color)"></i> 
              <div class="bottom">
                <h3>{{ $t('address') }}</h3>
                <a href="https://www.google.com/maps?ll=24.13494,120.636597&z=15&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=13315750471691712625">{{ $t('info.address')}}</a>
              </div>
            </div>
            <div class="email flex gap-2">
              <i class="pi pi-envelope lign-items-center justify-content-center" style="font-size: 2.5rem; color: var(--primary-darker-color)"></i>
              <div class="bottom">
                <h3>{{ $t('email') }}</h3>
                <a :href="`mailto:${$t('info.email')}`">{{ $t('info.email') }}</a>
              </div>
            </div>
            <div class="tel flex gap-2">
              <i class="pi pi-phone lign-items-center justify-content-center" style="font-size: 2.5rem; color: var(--primary-darker-color)"></i>
              <div class="bottom">
                <h3>{{ $t('telephone') }}</h3>
                <a :href="`tel:${$t('info.telephone')}`">{{ $t('info.telephone') }}</a>
              </div>
            </div>
          </div>
        </div>
        
        
        <Menubar class="col-10 col-offset-1" :model="items" :class="{'sticky': isSticky}">
          <template #menubuttonicon="">
            <div class="menubuttonicon">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>            
          </template>
          <template #item="{ item, props, hasSubmenu }">
              <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                  <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                      <span :class="item.icon" />
                      <span class="ml-2">{{  t(item.label) }}</span>
                  </a>
              </router-link>
              <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
                  <span :class="item.icon" />
                  <span class="ml-2">{{ t(item.label) }}</span>
                  <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down" />
              </a>
          </template>
          <template #end>
            <Dropdown v-model="selectedLang" :options="languages" optionLabel="name" placeholder="Select a Country" class="w-full md:w-6rem" @change="onLangChange">
              <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex align-items-center" style="height: 22px;">
                      <!-- <CountryFlag :country="slotProps.value.code.toLowerCase()" class="flex align-items-center justify-content-center"></CountryFlag> -->
                      <span :class="`fi fi-${slotProps.value.code.toLowerCase()} fis`"></span>
                      <!-- <img src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`mr-2 flag flag-${slotProps.value.code.toLowerCase()}`" style="width: 18px" /> -->
                      <div class="flex align-items-center justify-content-center">{{ slotProps.value.name }}</div>
                  </div>
                  <span v-else>
                      {{ slotProps.placeholder }}
                  </span>
              </template>
              <template #option="slotProps">
                  <div class="flex align-items-center" style="height: 22px;">
                    <!-- <CountryFlag :country="slotProps.option.code.toLowerCase()" class="flex align-items-center justify-content-center"></CountryFlag> -->
                    <span :class="`fi fi-${slotProps.option.code.toLowerCase()} fis`"></span>
                    <!-- <img src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" :class="`mr-2 flag flag-${slotProps.option.code.toLowerCase()}`" style="width: 18px" /> -->
                    <div class="flex align-items-center justify-content-center">{{ slotProps.option.name }}</div>
                  </div>
              </template>
          </Dropdown>
        </template>
      </Menubar>
      </div>
      <Header :title="$route.meta.name" :class="{'hidden': isHome}"></Header>
      <router-view class="col-12" :class="{'lg:col-10 lg:col-offset-1': !isHome}"></router-view>
      <Footer></Footer>
  </div>
</template>

<script setup>
// import info from './i18n/info.json';
import Menubar from 'primevue/menubar';
import Dropdown from 'primevue/dropdown';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import Line from './components/Line.vue';
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

import {watch, onMounted, computed} from 'vue'
import { useStore } from 'vuex';
const store = useStore();
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n({
  // inheritLocale: true,
  // useScope: 'local'
})

const lang = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'zh-TW'
const languages = ref([
  { name: '繁', code: 'tw', value: 'tw'},
  { name: '简', code: 'cn', value: 'cn'},
  // { name: 'EN', code: 'us', value: 'us'},
])
const selectedLang = ref(languages.value[0])

if (lang.startsWith('zh')) {
  if(lang != 'zh-TW' && lang != 'zh-HK') {
    selectedLang.value = languages.value[1]
    locale.value = 'cn'
  }
} else {
  selectedLang.value = languages.value[2]
  locale.value = 'us'
}






// mergeLocaleMessage('en', info.default.en)
// mergeLocaleMessage('tw', info.default.tw)
// mergeLocaleMessage('cn', info.default.cn)

const route = useRoute()

const isHome = computed(() => {
  return route.path == '/'
})

const onLangChange = (e) => {
  locale.value = e.value.code
  console.log(locale.value)
}

const onScroll = (e) => {
  isSticky.value = window.scrollY > 130
}

const isSticky = ref(false)

onBeforeMount(() => {
  window.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})

const productCategories = store.state.products.categories

const productNavList = [
  {
          label: 'products_categories.all',
          route: '/products/'
  },
]

for (const category of productCategories) {
  productNavList.push({
    label: 'products_categories.' + category.name,
    route: '/products/' + category.name 
  })
}

const items = ref([
  {
    label: 'nav.home',
    route: '/',
  },
  {
    label: 'nav.health-education',
    route: '/health-education/0',
  },
  {
    label: 'nav.about_us',
    route: '/about',
  },
  {
    label: 'nav.products',
    items: productNavList
  },
  {
    label: 'nav.services',
    route: '/services'
  },
  {
    label: 'nav.contact_us',
    route: '/contact'
  }
]);
</script>


<style lang="scss" scoped>


@media screen and (max-width: 1000px) {
  .top {
    .info {
      display: none !important;
    }
  }
  
}
.sticky:not(.p-menubar-mobile) {
  position: fixed !important;
  // width: inherit;
  top: 0;
  z-index: 100000;
}

.p-menubar-mobile {
  .menubuttonicon {
  width: 30px;
  height: 22px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: var(--menu-btton-icon-color);
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2),&:nth-child(3){
      top: 9px;
    }
    &:nth-child(4) {
      top: 18px;
    }
  }
}
  &.p-menubar-mobile-active{
    .menubuttonicon {
      span {
        &:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
      }
    }
  }
}
</style>