export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "tw",
    "resource": {
      "about": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾的開始"])},
        "sections": [
          {
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除了廣告品牌，今天起..你有更健康、更經濟的選擇!"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾生技創立於2006年, 有感台灣即將進入高齡社會，慢病、臥床失能、癌症患者營養需求亦逐年攀升，然而所需的營養補充品皆被大牌壟斷市場，因此我們成立專業營養研發團隊，致力將醫學營養普及，讓每位消費者能更經濟地提升健康與生活品質。\n"])}
          },
          {
            "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["視消費者為家人，<br>所以我更在乎安全和健康！"])},
            "descriptions": [
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好專業Our Exprtise"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">「專業營養師的專屬專用營養品」</span>以臨床營養為核心，探究消費者營養與健康需求，結合醫學、藥學、食品領域不斷創新研發，針對慢病、癌症、中老年族群等，打造科學、驗校的專用營養品，將預防醫學落實，精確細心呵護並改善消費者的生活。"])}
              },
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好安心Promise&Assurance"])},
                "content": [
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">研發:</span> 根據科學，不停創新"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">製造:</span> 全球選料，國際工廠"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">檢驗:</span> 絕對把關，批次檢驗"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"font-bold\">售後:</span> 千萬商險，營養答疑"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製造廠通過國際ISO-900，ISO-22000、HACCP品管"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全自動化氮氣充填生產製造設備"])},
                  (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣產物保險公司2000萬產品責任險"])}
                ]
              },
              {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好口碑Good Reputation"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從藥局、醫療器材通路到長照、居家照護，透過專業醫藥師、營養師、護理師的肯定與推薦，並獲選工商時報健康專欄「產品、包裝、口味」三大好評! 大禾寶養素和鉻寶養素等系列出口15個國家，讓千千萬萬使用者不僅獲得如家人般的用心的營養與健康補給，同時也能享受到美味口感!"])}
              }
            ]
          }
        ]
      }
    }
  })
}