export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "cn",
    "resource": {
      "Do you have the following questions?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你有以下问题吗？"])},
      "Actually, you might be experiencing these issues with your diet...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其实你可能在饮食出现了这些问题..."])},
      "Program details.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方案内容"])},
      "service": {
        "book_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/book_now.webp"])},
        "problems": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/problem_1.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/problem_2.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/problem_3.webp"])}
        ],
        "services": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/service_1.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/service_2.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/service_3.webp"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/service_4.webp"])}
        ],
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/cn/contact_us.webp"])},
        "members": [
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["董彦廷"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营养师"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/don.webp"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台北医学大学保健营养所硕士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofit线上减重班 营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚东医院形体美容中心 合作营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["山东孝养院老年养护机构 营养总监"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金颖生物科技 产品经理/营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知名上市企业 产品开发总监/营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机能食品与营养讲座 企业特聘讲师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纽特普健康科技 机能性食品开发顾问"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾高考营养师合格证书"])}
            ]
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["陈品言"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/chen.webp"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营养师"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国立海洋大学食品科学 硕士"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中山医学大学 学士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纽特普健康科技 创辨人"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度谱科技肿瘤病患营养顾问"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海肺科医院特聘讲师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TCI大江生医 首席讲师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上海百岳特 产品开发总监"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大禾生物科技 研发总监"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前台中荣总营养科研究员"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾高考营养师合格证书"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾高考食品技师合格证书"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HACCP 稽核员"])}
            ]
          },
          {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谢宗海"])},
            "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["images/xie.webp"])},
            "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营养师"])},
            "edu": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国立台湾大学食品科技所 硕士"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["义守大学 医学营养学系 学士"])}
            ],
            "exp": [
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金颖生物科技 行销企划/营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知名营养品公司 产品研发/营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["医院与社区疾病营养卫教 特聘讲师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亚东医院形体美容中心 合作营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线上减重减脂团班 营养师"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾高考营养师合格证书"])},
              (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾保健食品初级工程师"])}
            ]
          }
        ]
      }
    }
  })
}