<template>
  <div class="footer flex justify-content-between flex-wrap">
    <div class="flex justify-content-center col-12 logo">
      <img class="center" src="/images/logo.webp" alt=""/>
    </div>
    
    <div class="flex lg:col-offset-1 lg:col-5 md:col-6 col-offset-1 col-10 align-items-start justify-content-center flex-column story">
      <h1>{{ $t('title') }}</h1>
      <h3>{{ $t('subtitle') }}</h3>
      <p>{{ $t('description') }}</p>
    </div>
    
    <div class="flex lg:col-offset-1 lg:col-5 md:col-6 col-offset-1 col-10 align-items-start justify-content-center flex-column justify-content-between flex-wrap info">
      <h2>{{ $t('nav.contact_us') }}</h2>
      <div class="address flex gap-2 m-2">
        <i class="pi pi-map-marker" style="font-size: 2.0rem;"></i> 
        <div class="bottom">
          <p style="font-weight: bold;">{{ $t('address') }}</p>
          <a href="https://www.google.com/maps?ll=24.13494,120.636597&z=15&t=m&hl=zh-TW&gl=US&mapclient=embed&cid=13315750471691712625">{{ $t('info.address')}}</a>
        </div>
      </div>
      <div class="email flex gap-2 m-2">
        <i class="pi pi-envelope lign-items-center justify-content-center" style="font-size: 2.0rem;"></i>
        <div class="bottom">
          <p style="font-weight: bold;">{{ $t('email') }}</p>
          <a :href="`mailto:${$t('info.email')}`">{{ $t('info.email') }}</a>
        </div>
      </div>
      <div class="tel flex gap-2 m-2">
        <i class="pi pi-phone lign-items-center justify-content-center" style="font-size: 2.0rem;"></i>
        <div class="bottom">
          <p style="font-weight: bold;">{{ $t('telephone') }}</p>
          <a :href="`tel:${$t('info.telephone')}`">{{ $t('info.telephone') }}</a>
        </div>
      </div>
    </div>
    <Divider align="center" type="solid">
      <b>{{ $t('copyright.title') }}</b>
    </Divider>
    <div class="copyright col-12 align-items-center justify-content-center">{{$t('copyright.content')}}</div>
  </div>
  
</template>

<script setup>
import Divider from 'primevue/divider';
import { computed } from 'vue'
import { useStore } from 'vuex';
const store = useStore();
const news = computed(() => store.getters['news/data'])
</script>

<style lang="scss" scoped>

.copyright {
  text-align: center;
  margin: 15px 0;
}

.footer .p-divider .p-divider-content {
  color: var(--light-silver);
}

.logo {
  height: 120px;
}
</style>