<i18n locale="tw" lang="yml" src="@/locales/health-education/tw.yml"> 
</i18n>
<i18n locale="cn" lang="yml" src="@/locales/health-education/cn.yml"> 
</i18n>
<template>
  <div class="health-education flex flex-row-reverse flex-wrap">
    <div class="col-12 md:col-8 lg:col-9 flex flex-column flex-wrap align-items-left justify-content-start grid-nogutter">
      <h1>{{ t('health-education.' + $route.params.id + '.title') }}</h1>
      <img class="w-full" :src="t('health-education.' + $route.params.id + '.image')" alt="">
      <p style="text-align: right;">{{ t('health-education.' + $route.params.id + '.author') }}</p>
      <p v-html="t('health-education.' + $route.params.id + '.content')"></p>
      <div class="reference" v-if="tm(`health-education.${route.params.id}.ref`).length > 0">
        <p class="m-0">{{ t('references') }}</p>
        <ol class="m-0">
          <li v-for="r in tm(`health-education.${route.params.id}.ref`)">{{ rt(r) }}</li>
        </ol>
      </div>
      
    </div>
    <div class="col-12 md:col-4 lg:col-3 flex">
      <div class="latest-updates col-12">
        <h3>{{ $t('latest_updates') }}</h3>
        <div class="items flex flex-column">
          <div :class="{active: route.params.id == i}" class="link item flex m-0 align-items-center justify-content-center px-3" v-for="(n, i) in tm('health-education')" :key="i">
            <p><router-link :to="`/health-education/${i}`">{{ rt(n.title) }}</router-link></p><i class="pi pi-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
const route = useRoute();
const { t, rt, tm, locale } = useI18n({
  inheritLocale: true,
  useScope: 'local'
})

</script>

<style lang="scss" scoped>


.latest-updates {
  color: var(--deep-cinnamon-red);
  .link {
    position: relative;
    .pi-chevron-right {
      color: var(--soft-gold);
      position: absolute;
      right: 0px;
    }
    // border-bottom: 1px solid #fff;
    p {
      a {
        // color: #fff;
        &:hover {
          // color: #fff;
        }
      }
    }
    &:hover {
      // background-color: var(--bg-color);
      border-bottom: 1px solid var(--light-silver);
      .pi-chevron-right {
        color: var(--deep-cinnamon-red);
      }
    }
  }
  .active {
    // background-color: var(--bg-color);
    border-bottom: 1px solid var(--light-silver);
  }

  a { 
    display: block;
    width: 100%;
    height: 100%;
  }
  h3 {
    &:before {
      content: "";
      position: relative;
      height: 2px;
      border-radius: 8px;
      width: 40px;
      left: 12px;
      bottom: -43px;
      background-color: var(--bg-color);
      display: block;
    }
    &:after{
      position: relative;
      height: 6px;
      width: 6px;
      background-color: var(--bg-color);
      left: 0;
      bottom: -13px;
      content: "";
      border-radius: 50%;
      display: block;
    }
  }
  .item {
    margin: 1rem 0;
    .image {
      margin: 0 8px;
      img {
        border-radius: 5px;
      }
    }
    
    .info {
      p {
        margin: 8px 0;
      }
    }
  }
  
}


</style>
